import { useNavigate, useParams } from "react-router-dom";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState, useSyncExternalStore } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import MDTypography from "components/MDTypography";
import Grid2 from "@mui/material/Unstable_Grid2";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DataTable from "examples/Tables/DataTable";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from "@mui/material/Tooltip";
import MDButton from "components/MDButton";

function AdsFilter() {
    const { userId } = useParams();
    const { adsId } = useParams();

    const [usdtUahPriceBuy, setUsdtUahPriceBuy] = useState("");
    const [usdtUahPriceSell, setUsdtUahPriceSell] = useState("");
    const [btcPrice, setBtcPrice] = useState("");
    const [ethPrice, setEthPrice] = useState("");
    const [bnbPrice, setBnbPrice] = useState("");

    const [priceMin, setPriceMin] = useState("");
    const [priceMax, setPriceMax] = useState("");
    const [volumeMin, setVolumeMin] = useState("");
    const [volumeMax, setVolumeMax] = useState("");
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [nickname, setNickname] = useState("");
    const [exchangeName, setExchangeName] = useState("");
    const [side, setSide] = useState("");
    const [ticker, setTicker] = useState("");
    const [currentPrice, setCurrentPrice] = useState("");
    const [targetPrice, setTargetPrice] = useState("");
    const [adsPaymentMethods, setAdsPaymentMethods] = useState([]);
 
    const [paymentMethodsState, setPaymentMethodsState] = useState(
        {
            Monobank: false,
            PrivatBank: false,
            PUMBBank: false,
            ABank: false,
            RaiffeisenBankAval: false,
            Oschadbank: false,
            izibank: false,
            SenseSuperApp: false,
            BankVlasnyiRakhunok: false,
            OTPBankNew: false,
            Ukrsibbank: false,
            Monobankiban: false,
            IdeaBank: false,
            Ukrgasbank: false,
            CreditAgricole: false,
            PUMBIBAN: false,
            BankIBAN: false,
            KredoBank: false,
            AbankIBAN: false,
            Tascombank: false,
            BankPivdenny: false,
            AccordBank: false,
            BankCreditDnipro: false,
            Sportbank: false,
            PravexBank: false,
            Ukreximbank: false,
            SenseSuperAppIBAN: false,
            GEOPay: false,
            RaiffeisenAvalIBAN: false,
            OschadBankIBAN: false,
            PiraeusBank: false,
            IzibankIBAN: false,
            Radabank: false,
            CystalBank: false,
            OTPBANKIBAN: false,
            Ukrsibbankiban: false,
            BankVlasnyiIBAN: false,
            IdeaBankIBAN: false,
            TascombankIBAN: false,
            BankCreditDniproIBAN: false,
            BankPivdennyIBAN: false,
            RaiffeisenBKredoBankIBANankAval: false,
            AccordbankIBAN: false,
            CreditAgricoleIBAN: false,
            SettlePay: false,
            SportbankIBAN: false,
            UkrgasbankIBAN: false,
            JordanIslamicBank: false,
            PravexBankIBAN: false,
            UkreximbankIBAN: false
        }
    );

    const [timeFiltersState, setTimeFiltersState] = useState(
        {
            minutes_15: false,
            minutes_30: false,
            minutes_180: false
        }
    );

    useEffect(() => {
        document.title = "Dashboard | P2P Bot";
    }, []);

    const setData = (data) => {
        setNickname(data.nickname);
        setExchangeName("Binance");
        setSide(data.side);
        setTicker(data.asset);
        setCurrentPrice(data.price);
        setTargetPrice(data.target_price);
        setAdsPaymentMethods(data.payment_methods);
    }

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem("_token");

            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/dashboard/ads/info?user_id=${userId}&ads_id=${adsId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                const data = response.data.ads_info;
                const config = response.data.config;

                if (config !== null) {
                    updatePaymentMethodsState(config.bank_settings);
                    setUsdtUahPriceBuy(config.usdt_uah_price_buy);
                    setUsdtUahPriceSell(config.usdt_uah_price_sell);
                    setPriceMin(config.price_min);
                    setPriceMax(config.price_max);
                    setVolumeMin(config.volume.min);
                    setVolumeMax(config.volume.max);
                    setTimeFiltersState(config.time_filters);
                }

                setData(data);
                setBtcPrice(response.data.prices.btc_uah_price);
                setEthPrice(response.data.prices.eth_uah_price);
                setBnbPrice(response.data.prices.bnb_uah_price);

            } catch (error) {
                if (error.code === "ERR_NETWORK") {
                    enqueueSnackbar("No connection with server", {
                        variant: "error",
                        persist: true,
                        anchorOrigin: { horizontal: "center", vertical: "top" },
                    });
                } else if (error.response.status === 401) {
                    navigate("/sign-in");
                }
            }
        };
        fetchData();

        const intervalId = setInterval(fetchData, 120 * 1000);
        return () => clearInterval(intervalId);
    }, []);

    const updatePaymentMethodsState = (paymentMethodsFromServer) => {
        setPaymentMethodsState((prevState) => {
            const updatedState = { ...prevState };
            for (let key in paymentMethodsFromServer) {
                if (updatedState.hasOwnProperty(key)) {
                    updatedState[key] = paymentMethodsFromServer[key];
                }
            }

            return updatedState;
        });
    };

    const applyFilters = async () => {
        const token = localStorage.getItem("_token");
        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}/dashboard/ads/config`,
                {
                    nickname: nickname,
                    ads_id: adsId,
                    bank_settings: paymentMethodsState,
                    time_filters: timeFiltersState,
                    price_min: priceMin,
                    price_max: priceMax,
                    current_price: currentPrice,
                    volume: {
                        min: volumeMin,
                        max: volumeMax,
                    }
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
        } catch (error) {
            if (error.code === "ERR_NETWORK") {
                enqueueSnackbar("No connection with server", {
                    variant: "error",
                    persist: true,
                    anchorOrigin: { horizontal: "center", vertical: "top" },
                });
            } else if (error.response.status === 401) {
                navigate("/sign-in");
            }
        }
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setPaymentMethodsState((prevState) => ({
            ...prevState,
            [name]: checked,
        }));
    };

    const handleTimeFiltersChange = (event) => {
        const { name, checked } = event.target;
        setTimeFiltersState((prevState) => ({
            ...prevState,
            [name]: checked,
        }));
    };

    const handleUsdtUahBuyChange = (e) => {
        const newUsdtUahPriceBuy = e.target.value;
        setUsdtUahPriceBuy(newUsdtUahPriceBuy);
    };

    const handleUsdtUahSellChange = (e) => {
        const newUsdtUahPriceSell = e.target.value;
        setUsdtUahPriceSell(newUsdtUahPriceSell);
    };

    const handlePriceMinChange = (e) => {
        const newPriceMin = e.target.value;
        setPriceMin(newPriceMin);
    };

    const handlePriceMaxChange = (e) => {
        const newPriceMax = e.target.value;
        setPriceMax(newPriceMax);
    };

    const handleVolumeMinChange = (e) => {
        const newVolumeMin = e.target.value;
        setVolumeMin(newVolumeMin);
    };

    const handleVolumeMaxchange = (e) => {
        const newVolumeMax = e.target.value;
        setVolumeMax(newVolumeMax);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={1} pb={1}>
                <Grid container direction="row">
                    <Grid item xs={2}>
                        <MDInput
                            name="min_volume"
                            type="text"
                            label="USDT / UAH (buy)"
                            onChange={handleUsdtUahBuyChange}
                            value={usdtUahPriceBuy}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <MDInput
                            name="min_volume"
                            type="text"
                            label="USDT / UAH (sell)"
                            onChange={handleUsdtUahSellChange}
                            value={usdtUahPriceSell}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <MDTypography component="span" variant="subtitle1" color="text" fontWeight="medium">
                            BTC / UAH: {btcPrice}
                        </MDTypography>
                    </Grid>
                    <Grid item xs={2}>
                        <MDTypography component="span" variant="subtitle1" color="text" fontWeight="medium">
                            ETH / UAH: {ethPrice}
                        </MDTypography>
                    </Grid>
                    <Grid item xs={2}>
                        <MDTypography component="span" variant="subtitle1" color="text" fontWeight="medium">
                            BNB / UAH: {bnbPrice}
                        </MDTypography>
                    </Grid>
                </Grid>
            </MDBox>
            <MDBox pt={1} pb={1}>
                <Accordion sx={{ backgroundColor: "inherit", border: "solid 2px", borderRadius: "30px", borderColor: "secondary.main" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: "secondary.main" }} />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{ color: "secondary.main" }}
                    >
                        Filters
                    </AccordionSummary>
                    <AccordionDetails>
                        <MDBox>
                            <Card>
                                <MDBox m={2}>
                                    <Grid container spacing={2} direction="row">
                                        <Grid item xs={2}>
                                            <Grid container spacing={2} direction="column">
                                                <Grid item xs={4}>
                                                    <Tooltip title="Minimum UAH amount in order for concurrent" placement="top">
                                                        <MDInput
                                                            name="min_volume"
                                                            type="text"
                                                            label="Volume min."
                                                            onChange={handleVolumeMinChange}
                                                            value={volumeMin}
                                                        />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <MDInput
                                                        name="price_min"
                                                        type="text"
                                                        label="Price min."
                                                        onChange={handlePriceMinChange}
                                                        value={priceMin}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Grid container spacing={2} direction="column">
                                                <Grid item xs={4}>
                                                    <Tooltip title="Maximum UAH amount in order for concurrent" placement="top">
                                                        <MDInput
                                                            name="max_volume"
                                                            type="text"
                                                            label="Volume max."
                                                            onChange={handleVolumeMaxchange}
                                                            value={volumeMax}
                                                        />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <MDInput
                                                        name="price_max"
                                                        type="text"
                                                        label="Price max."
                                                        onChange={handlePriceMaxChange}
                                                        value={priceMax}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox name="Monobank" onChange={handleCheckboxChange} checked={paymentMethodsState.Monobank} />} key="Monobank" label="Monobank" />
                                                <FormControlLabel control={<Checkbox name="PrivatBank" onChange={handleCheckboxChange} checked={paymentMethodsState.PrivatBank} />} key="PrivatBank" label="PrivatBank" />
                                                <FormControlLabel control={<Checkbox name="PUMBBank" onChange={handleCheckboxChange} checked={paymentMethodsState.PUMBBank} />} key="PUMBBank" label="PUMBBank" />
                                                <FormControlLabel control={<Checkbox name="ABank" onChange={handleCheckboxChange} checked={paymentMethodsState.ABank} />} key="ABank" label="ABank" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox name="RaiffeisenBankAval" onChange={handleCheckboxChange} checked={paymentMethodsState.RaiffeisenBankAval} />} key="RaiffeisenBankAval" label="RaiffeisenBankAval" />
                                                <FormControlLabel control={<Checkbox name="Oschadbank" onChange={handleCheckboxChange} checked={paymentMethodsState.Oschadbank} />} key="Oschadbank" label="Oschadbank" />
                                                <FormControlLabel control={<Checkbox name="izibank" onChange={handleCheckboxChange} checked={paymentMethodsState.izibank} />} key="izibank" label="izibank" />
                                                <FormControlLabel control={<Checkbox name="SenseSuperApp" onChange={handleCheckboxChange} checked={paymentMethodsState.SenseSuperApp} />} key="SenseSuperApp" label="SenseSuperApp" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox name="BankVlasnyiRakhunok" onChange={handleCheckboxChange} checked={paymentMethodsState.BankVlasnyiRakhunok} />} key="BankVlasnyiRakhunok" label="BankVlasnyiRakhunok" />
                                                <FormControlLabel control={<Checkbox name="OTPBankNew" onChange={handleCheckboxChange} checked={paymentMethodsState.OTPBankNew} />} key="OTPBankNew" label="OTPBankNew" />
                                                <FormControlLabel control={<Checkbox name="Ukrsibbank" onChange={handleCheckboxChange} checked={paymentMethodsState.Ukrsibbank} />} key="Ukrsibbank" label="Ukrsibbank" />
                                                <FormControlLabel control={<Checkbox name="Monobankiban" onChange={handleCheckboxChange} checked={paymentMethodsState.Monobankiban} />} key="Monobankiban" label="Monobankiban" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox name="IdeaBank" onChange={handleCheckboxChange} checked={paymentMethodsState.IdeaBank} />} key="IdeaBank" label="IdeaBank" />
                                                <FormControlLabel control={<Checkbox name="Ukrgasbank" onChange={handleCheckboxChange} checked={paymentMethodsState.Ukrgasbank} />} key="Ukrgasbank" label="Ukrgasbank" />
                                                <FormControlLabel control={<Checkbox name="CreditAgricole" onChange={handleCheckboxChange} checked={paymentMethodsState.CreditAgricole} />} key="CreditAgricole" label="CreditAgricole" />
                                                <FormControlLabel control={<Checkbox name="PUMBIBAN" onChange={handleCheckboxChange} checked={paymentMethodsState.PUMBIBAN} />} key="PUMBIBAN" label="PUMBIBAN" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox name="BankIBAN" onChange={handleCheckboxChange} checked={paymentMethodsState.BankIBAN} />} key="BankIBAN" label="BankIBAN" />
                                                <FormControlLabel control={<Checkbox name="KredoBank" onChange={handleCheckboxChange} checked={paymentMethodsState.KredoBank} />} key="KredoBank" label="KredoBank" />
                                                <FormControlLabel control={<Checkbox name="AbankIBAN" onChange={handleCheckboxChange} checked={paymentMethodsState.AbankIBAN} />} key="AbankIBAN" label="AbankIBAN" />
                                                <FormControlLabel control={<Checkbox name="Tascombank" onChange={handleCheckboxChange} checked={paymentMethodsState.Tascombank} />} key="Tascombank" label="Tascombank" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox name="BankPivdenny" onChange={handleCheckboxChange} checked={paymentMethodsState.BankPivdenny} />} key="BankPivdenny" label="BankPivdenny" />
                                                <FormControlLabel control={<Checkbox name="AccordBank" onChange={handleCheckboxChange} checked={paymentMethodsState.AccordBank} />} key="AccordBank" label="AccordBank" />
                                                <FormControlLabel control={<Checkbox name="BankCreditDnipro" onChange={handleCheckboxChange} checked={paymentMethodsState.BankCreditDnipro} />} key="BankCreditDnipro" label="BankCreditDnipro" />
                                                <FormControlLabel control={<Checkbox name="Sportbank" onChange={handleCheckboxChange} checked={paymentMethodsState.Sportbank} />} key="Sportbank" label="Sportbank" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox name="PravexBank" onChange={handleCheckboxChange} checked={paymentMethodsState.PravexBank} />} key="PravexBank" label="PravexBank" />
                                                <FormControlLabel control={<Checkbox name="Ukreximbank" onChange={handleCheckboxChange} checked={paymentMethodsState.Ukreximbank} />} key="Ukreximbank" label="Ukreximbank" />
                                                <FormControlLabel control={<Checkbox name="SenseSuperAppIBAN" onChange={handleCheckboxChange} checked={paymentMethodsState.SenseSuperAppIBAN} />} key="SenseSuperAppIBAN" label="SenseSuperAppIBAN" />
                                                <FormControlLabel control={<Checkbox name="GEOPay" onChange={handleCheckboxChange} checked={paymentMethodsState.GEOPay} />} key="GEOPay" label="GEOPay" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox name="RaiffeisenAvalIBAN" onChange={handleCheckboxChange} checked={paymentMethodsState.RaiffeisenAvalIBAN} />} key="RaiffeisenAvalIBAN" label="RaiffeisenAvalIBAN" />
                                                <FormControlLabel control={<Checkbox name="OschadBankIBAN" onChange={handleCheckboxChange} checked={paymentMethodsState.OschadBankIBAN} />} key="OschadBankIBAN" label="OschadBankIBAN" />
                                                <FormControlLabel control={<Checkbox name="PiraeusBank" onChange={handleCheckboxChange} checked={paymentMethodsState.PiraeusBank} />} key="PiraeusBank" label="PiraeusBank" />
                                                <FormControlLabel control={<Checkbox name="IzibankIBAN" onChange={handleCheckboxChange} checked={paymentMethodsState.IzibankIBAN} />} key="IzibankIBAN" label="IzibankIBAN" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox name="Radabank" onChange={handleCheckboxChange} checked={paymentMethodsState.Radabank} />} key="Radabank" label="Radabank" />
                                                <FormControlLabel control={<Checkbox name="CystalBank" onChange={handleCheckboxChange} checked={paymentMethodsState.CystalBank} />} key="CystalBank" label="CystalBank" />
                                                <FormControlLabel control={<Checkbox name="OTPBANKIBAN" onChange={handleCheckboxChange} checked={paymentMethodsState.OTPBANKIBAN} />} key="OTPBANKIBAN" label="OTPBANKIBAN" />
                                                <FormControlLabel control={<Checkbox name="Ukrsibbankiban" onChange={handleCheckboxChange} checked={paymentMethodsState.Ukrsibbankiban} />} key="Ukrsibbankiban" label="Ukrsibbankiban" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox name="BankVlasnyiIBAN" onChange={handleCheckboxChange} checked={paymentMethodsState.BankVlasnyiIBAN} />} key="BankVlasnyiIBAN" label="BankVlasnyiIBAN" />
                                                <FormControlLabel control={<Checkbox name="IdeaBankIBAN" onChange={handleCheckboxChange} checked={paymentMethodsState.IdeaBankIBAN} />} key="IdeaBankIBAN" label="IdeaBankIBAN" />
                                                <FormControlLabel control={<Checkbox name="TascombankIBAN" onChange={handleCheckboxChange} checked={paymentMethodsState.TascombankIBAN} />} key="TascombankIBAN" label="TascombankIBAN" />
                                                <FormControlLabel control={<Checkbox name="BankCreditDniproIBAN" onChange={handleCheckboxChange} checked={paymentMethodsState.BankCreditDniproIBAN} />} key="BankCreditDniproIBAN" label="BankCreditDniproIBAN" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox name="BankPivdennyIBAN" onChange={handleCheckboxChange} checked={paymentMethodsState.BankPivdennyIBAN} />} key="BankPivdennyIBAN" label="BankPivdennyIBAN" />
                                                <FormControlLabel control={<Checkbox name="RaiffeisenBKredoBankIBANankAval" onChange={handleCheckboxChange} checked={paymentMethodsState.RaiffeisenBKredoBankIBANankAval} />} key="RaiffeisenBKredoBankIBANankAval" label="KredoBankIBAN" />
                                                <FormControlLabel control={<Checkbox name="AccordbankIBAN" onChange={handleCheckboxChange} checked={paymentMethodsState.AccordbankIBAN} />} key="AccordbankIBAN" label="AccordbankIBAN" />
                                                <FormControlLabel control={<Checkbox name="CreditAgricoleIBAN" onChange={handleCheckboxChange} checked={paymentMethodsState.CreditAgricoleIBAN} />} key="CreditAgricoleIBAN" label="CreditAgricoleIBAN" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox name="SettlePay" onChange={handleCheckboxChange} checked={paymentMethodsState.SettlePay} />} key="SettlePay" label="SettlePay" />
                                                <FormControlLabel control={<Checkbox name="SportbankIBAN" onChange={handleCheckboxChange} checked={paymentMethodsState.SportbankIBAN} />} key="SportbankIBAN" label="SportbankIBAN" />
                                                <FormControlLabel control={<Checkbox name="UkrgasbankIBAN" onChange={handleCheckboxChange} checked={paymentMethodsState.UkrgasbankIBAN} />} key="UkrgasbankIBAN" label="UkrgasbankIBAN" />
                                                <FormControlLabel control={<Checkbox name="JordanIslamicBank" onChange={handleCheckboxChange} checked={paymentMethodsState.JordanIslamicBank} />} key="JordanIslamicBank" label="JordanIslamicBank" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox name="PravexBankIBAN" onChange={handleCheckboxChange} checked={paymentMethodsState.PravexBankIBAN} />} key="PravexBankIBAN" label="PravexBankIBAN" />
                                                <FormControlLabel control={<Checkbox name="UkreximbankIBAN" onChange={handleCheckboxChange} checked={paymentMethodsState.UkreximbankIBAN} />} key="UkreximbankIBAN" label="UkreximbankIBAN" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Tooltip title="Order executing time filter" placement="top">
                                                <MDTypography variant="h6">
                                                    Time filter
                                                </MDTypography>
                                                <FormGroup>
                                                    <FormControlLabel control={<Checkbox name="minutes_15" onChange={handleTimeFiltersChange} checked={timeFiltersState.minutes_15} />} key="minutes_15" label="To 15 minutes" />
                                                    <FormControlLabel control={<Checkbox name="minutes_30" onChange={handleTimeFiltersChange} checked={timeFiltersState.minutes_30} />} key="minutes_30" label="To 30 minutes" />
                                                    <FormControlLabel control={<Checkbox name="minutes_180" onChange={handleTimeFiltersChange} checked={timeFiltersState.minutes_180} />} key="minutes_180" label="To 180 minutes" />
                                                </FormGroup>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </MDBox>
                                <MDBox m={2}>
                                    <MDButton variant="gradient" color="info" size="small" onClick={applyFilters}>
                                        Apply
                                    </MDButton>
                                </MDBox>
                            </Card>
                        </MDBox>
                    </AccordionDetails>
                </Accordion>
            </MDBox>
            <MDBox>
                <MDBox>
                    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        User Nickname: &nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp;{nickname}
                    </MDTypography>
                </MDBox>
                <MDBox>
                    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        ADS ID: &nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp;{adsId}
                    </MDTypography>
                </MDBox>
                <MDBox>
                    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        Exchange name: &nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp;{exchangeName}
                    </MDTypography>
                </MDBox>
                <MDBox>
                    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        Side: &nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp;{side}
                    </MDTypography>
                </MDBox>
                <MDBox>
                    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        Ticker: &nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp;{ticker}
                    </MDTypography>
                </MDBox>
                <MDBox>
                    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        Current Price: &nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp;{currentPrice}
                    </MDTypography>
                </MDBox>
                <MDBox>
                    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        Target Price: &nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp;{targetPrice}
                    </MDTypography>
                </MDBox>
                <MDBox>
                    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        Payment Methods: &nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp;{adsPaymentMethods.join('; ')}.
                    </MDTypography>
                </MDBox>
            </MDBox>
        </DashboardLayout>
    );
}

export default AdsFilter;
